import React, { useState } from "react";
import { auth, db } from "../../firebase";
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/mentorable_logo.png";


const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log(user)
      // Fetch user data from Firestore
      const userRef = doc(db, "coaches", user.uid);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        const userData = userSnap.data();
        if (userData.profileCompleted) {
          navigate("/coach/"); // Profile complete → Go to home
        } else {
          navigate("/coach/complete-profile"); // Incomplete → Go to profile completion page
        }
      } else {
        setError("User data not found.");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      alert("Google Sign-In Successful!");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center ">
      <div className="area w-full top-0 left-0 h-full">
                <ul className="circles">
                    <li className="bg-[#84d4d8]"></li>
                    <li className="bg-[#a43bcb]"></li>
                    <li className="bg-[#e293ac]"></li>
                    <li className="bg-[#fb9160]"></li>
                    <li className="bg-[#84d4d8]"></li>
                    <li className="bg-[#fcce90]"></li>
                    <li className="bg-[#a43bcb]"></li>
                    <li className="bg-[#fb9160]"></li>
                    <li className="bg-[#e293ac]"></li>
                    <li className="bg-[#84d4d8]"></li>
                    <li className="bg-[#fcce90]"></li>
                    <li className="bg-[#e293ac]"></li>
                    <li className="bg-[#a43bcb]"></li>
                    <li className="bg-[#fb9160]"></li>
                </ul>
            </div>
      <div className="bg-white/90  shadow-lg rounded-lg p-8 max-w-md w-full">
      {/* <img
                className=" flex items-center justify-center md:w-[15%] "
                src={logo}
            /> */}
        <h2 className="comic-sans-medium text-2xl font-bold text-gray-800 mb-6 text-center">Coach Login</h2>
        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label htmlFor="email" className="comic-sans-small block text-gray-800 font-medium mb-1">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="w-full bg-gray-100 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-[#F0CF2D]"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="comic-sans-small block text-gray-800 font-medium mb-1">
              Password
            </label>
            <input
              type="password"
              id="password"
              className="w-full bg-gray-100 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-[#F0CF2D]"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="comic-sans-small w-full bg-[#F0CF2D] text-gray-800 py-2 rounded-md hover:bg-[#E1BC28] font-semibold"
          >
            Log In
          </button>
        </form>
        <p className="comic-sans-tiny text-gray-600 text-sm text-center mt-4">
          Haven't registered yet?{" "}
          <Link to="/coach/register" className="text-gray-800 hover:text-[#F0CF2D] hover:underline font-medium">
            Sign up here
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignIn;