import React from "react";
import Blogs from "./Blogs";
import Footer from "./Footer";
import Hero from "./Hero";
import Courses from "./Courses";
import Navbar from "./Navbar";
import PediatricianApproved from "./PediatricianApproved";
import SummerCamp from "./SummerCamp";
import Newsletter from "./Newsletter";
import Divider from "./Divider";
import TobBar from "./TobBar";
import Testimony from "./Testimony";
import Description from "./Description";
import MentorableVideo from "./MentorableVideo";
import DiasporaEvent from "./DiasporaEvent";
import KidsVideo from "./KidsVideo";
import Know from "./Know";
import OnlineCamp from "./OnlineCamp";

function StudentHomePage() {
  return (
    <div className="w-full sm:flex flex-col">
      <TobBar />
      {/* <Navbar /> */}
      <div className="space-y-10 mt-10">
        <Hero className="ml-20 m-0" />
        <Divider />
        <Know />
        <KidsVideo />
        <Divider />
        {/* <MentorableVideo /> */}
        <Courses />
        <Divider />
        <OnlineCamp />
        <Divider />
        <Blogs />
        <Description />
        {/* <div className="row-span-10 ..."></div> */}
        <Testimony />
        {/* <DiasporaEvent /> */}
        <SummerCamp />
        <PediatricianApproved />
        <Divider />
        <Newsletter />
      </div>
      <Footer />
    </div>
  );
}

export default StudentHomePage;
