import React from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/mentorable_logo.png";

const LandingPage = () => {
    const navigate = useNavigate();

  const handleSelection = (role) => {
    localStorage.setItem("userRole", role); // Store the role in localStorage
    // if (role === "student") {
    //   navigate("/student-home"); // Navigate to student portal
    // } else {
    //   navigate("/coach-home"); // Navigate to coach portal
    // }
  };

    return (
        <div className="flex flex-col items-center min-h-screen relative overflow-hidden">
            <div className="area w-full top-0 left-0 h-full">
                <ul className="circles">
                    <li className="bg-[#84d4d8]"></li>
                    <li className="bg-[#a43bcb]"></li>
                    <li className="bg-[#e293ac]"></li>
                    <li className="bg-[#fb9160]"></li>
                    <li className="bg-[#84d4d8]"></li>
                    <li className="bg-[#fcce90]"></li>
                    <li className="bg-[#a43bcb]"></li>
                    <li className="bg-[#fb9160]"></li>
                    <li className="bg-[#e293ac]"></li>
                    <li className="bg-[#84d4d8]"></li>
                    <li className="bg-[#fcce90]"></li>
                    <li className="bg-[#e293ac]"></li>
                    <li className="bg-[#a43bcb]"></li>
                    <li className="bg-[#fb9160]"></li>
                </ul>
            </div>
            <img
                className=" md:w-[25%] "
                src={logo}
            />
            <h1 className="comic-sans-medium text-yellow-100 text-4xl md:text-6xl font-bold mb-20 text-center">
                Welcome to Mentorable!
            </h1>
            <p className="comic-sans-medium text-yellow-100 text-lg md:text-xl text-center mb-10">
                Select your portal to proceed.
            </p>
            <div className="flex flex-col gap-10 md:flex-row md:gap-20">
                <Link
                    to="/student"
                    onClick={() => handleSelection("student")}
                    className="bg-[#84d4d8] text-blue-600 comic-sans-small font-semibold px-8 py-4 rounded-lg shadow-lg hover:bg-gray-200 transition duration-300 text-center"
                >
                    Student Portal
                </Link>
                <Link
                    to="/coach/signin"
                    onClick={() => handleSelection("coach")}
                    className="bg-[#fb9160] text-blue-600 comic-sans-small font-semibold px-8 py-4 rounded-lg shadow-lg hover:bg-gray-200 transition duration-300 text-center"
                >
                    Coach Portal
                </Link>
            </div>
        </div>
    );
};

export default LandingPage;