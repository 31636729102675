import React, { useState } from "react";
import { storage, auth, db } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const CompleteProfile = () => {
    const [formData, setFormData] = useState({
        phoneNumber: "",
        degree: "",
        university: "",
        subcity: "",
        woreda: ""
    });
    const [photo, setPhoto] = useState(null);
    const [cv, setCv] = useState(null);
    const [uploadProgress, setUploadProgress] = useState({ photo: 0, cv: 0 });
    const [uploading, setUploading] = useState(false);
    const [status, setStatus] = useState("Pending Approval");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const userId = auth.currentUser?.uid;

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e, type) => {
        if (type === "photo") setPhoto(e.target.files[0]);
        if (type === "cv") setCv(e.target.files[0]);
    };

    const handleFileUpload = async (file, type) => {
        if (!file) return null;

        setUploading(true);
        const formData = new FormData();
        formData.append("UPLOADCARE_PUB_KEY", "b3f0dbde0df8afb4a197");
        formData.append("file", file);
        // formData.append("folder", `coaches/${userId}`);

        try {
            const response = await fetch("https://upload.uploadcare.com/base/", {
                method: "POST",
                body: formData,
            });

            const data = await response.json();
            setUploading(false);
            return `https://ucarecdn.com/${data.file}/`; // Returns the uploaded file's URL
        } catch (error) {
            console.error("Upload failed:", error);
            setUploading(false);
            return null;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!userId) {
            alert("You must be logged in.");
            return;
        }

        setUploading(true);
        setLoading(true);
        try {
            const photoUrl = photo ? await handleFileUpload(photo) : null;
            const cvUrl = cv ? await handleFileUpload(cv) : null;

            const coachRef = doc(db, "coaches", userId);
            await updateDoc(coachRef, {
                ...formData,
                photo: photoUrl,
                cv: cvUrl,
                status: "Pending Approval",
                profileCompleted: "true"
            });

            setStatus("Pending Approval");
            navigate("/coach/");
            alert("Profile updated successfully!");
        } catch (error) {
            console.error("Error updating profile:", error);
            alert("Failed to update profile.");
        }

        setUploading(false);
        setLoading(false);
    };

    return (
        <div className="min-h-screen flex items-center justify-center p-6 pt-[110px]">
            <div className="bg-white/90 shadow-lg rounded-lg p-6 max-w-2xl w-full">
                <h2 className="comic-sans-medium text-2xl font-bold text-gray-800 mb-4 text-center">Complete Your Profile</h2>
                <p className="comic-sans-tiny text-center text-gray-600 text-sm mb-6">
                    Your profile is <span className="font-semibold text-yellow-600">{status}</span>. In the meantime, complete your profile.
                </p>

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="text-gray-700 font-medium mb-1">Profile Picture</label>
                        <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, "photo")} className="border border-gray-300 p-2 rounded w-full" />
                        {uploadProgress.photo > 0 && <progress value={uploadProgress.photo} max="100" className="w-full mt-2" />}
                    </div>
                    <div>
                        <label className="text-gray-700 font-medium mb-1">Upload CV</label>
                        <input type="file" accept=".pdf,.doc,.docx" onChange={(e) => handleFileChange(e, "cv")} className="border border-gray-300 p-2 rounded w-full" />
                        {uploadProgress.cv > 0 && <progress value={uploadProgress.cv} max="100" className="w-full mt-2" />}
                    </div>
                    <div>
                        <label className="text-gray-700 font-medium mb-1">Phone Number</label>
                        <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} className="border border-gray-300 p-2 rounded w-full" placeholder="Enter your phone number" required />
                    </div>
                    <div>
                        <label className="text-gray-700 font-medium mb-1">University Degree</label>
                        <input type="text" name="degree" value={formData.degree} onChange={handleChange} className="border border-gray-300 p-2 rounded w-full" placeholder="Enter your major" required />
                    </div>
                    <div>
                        <label className="text-gray-700 font-medium mb-1">Name of University You Graduated From</label>
                        <input type="text" name="university" value={formData.university} onChange={handleChange} className="border border-gray-300 p-2 rounded w-full" placeholder="Enter your university name" required />
                    </div>
                    <div>
                        <label className="text-gray-700 font-medium mb-1">Kifle Ketema</label>
                        <input type="text" name="subcity" value={formData.subcity} onChange={handleChange} className="border border-gray-300 p-2 rounded w-full" placeholder="Enter your specific address" required />
                    </div>
                    <div>
                        <label className="text-gray-700 font-medium mb-1">Woreda</label>
                        <input type="text" name="woreda" value={formData.woreda} onChange={handleChange} className="border border-gray-300 p-2 rounded w-full" placeholder="Enter your specific address" required />
                    </div>
                    <button type="submit" className="w-full bg-[#F0CF2D] text-gray-800 py-2 rounded-md hover:bg-blue-700 font-semibold">
                        {uploading ? "Uploading..." : "Update Profile"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CompleteProfile;