import React, { useState, useEffect } from "react";
import { TextField, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { db } from "../../../firebase";
import { collection, getDocs } from "firebase/firestore";

function SignUpForm({
  handleSubmit,
  openModal,
  handleClose,
  loginWithUsernameAndPassword,
  loginWithGoogle,
  handleOpenSignIn,
  signupWithUsernameAndPassword,
}) {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    phoneNumber: "",
    grade: "",
    age: "",
    school: "",
    coachSelection: "auto", // 'auto' or 'manual'
    coachId: "",
  });

  const [coaches, setCoaches] = useState([]);
  const [currentCoachIndex, setCurrentCoachIndex] = useState(0);

  useEffect(() => {
    const fetchCoaches = async () => {
      const coachesCollection = await getDocs(collection(db, "coaches"));
      const coachList = coachesCollection.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCoaches(coachList);
    };
    fetchCoaches();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = (name, value) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getNextCoach = () => {
    if (coaches.length === 0) return "";
    const nextIndex = currentCoachIndex % coaches.length;
    setCurrentCoachIndex((prevIndex) => (prevIndex + 1) % coaches.length);
    return coaches[nextIndex].id;
  };


  const handleLoginWithUsernameAndPassword = (e) => {
    loginWithUsernameAndPassword(e, formData.email, formData.password);
  };

  const handleLoginWithGoogle = () => {
    loginWithGoogle();
  };

  const handleSignUpWithUsernameAndPassword = async (e) => {
    e.preventDefault();
  
    // Ensure we have the correct data to pass to the signup function
    const assignedCoachId = formData.coachSelection === "auto" ? getNextCoach() : formData.coachId;
  
    const data = {
      firstname: formData.firstname || "",
      lastname: formData.lastname || "",
      grade: formData.grade || "",
      email: formData.email || "",
      age: formData.age || "",
      phoneNumber: formData.phoneNumber,
      school: formData.school,
      coachSelection: formData.coachSelection,
      coachId: assignedCoachId,
    };
  
    // Call the signup function and pass all necessary data
    signupWithUsernameAndPassword(e, formData.email, formData.password, data);
  };  

  const handleSubmitButton = (e, email, password) => {
    handleSubmit(e, email, password);
  };
  return (
    <div className="max-w-md mx-auto p-6 bg-white shadow-md rounded-lg">
      <form onSubmit={handleSignUpWithUsernameAndPassword} className="space-y-4">
        <TextField label="First Name" name="firstname" value={formData.firstname} onChange={handleChange} fullWidth required />
        <TextField label="Last Name" name="lastname" value={formData.lastname} onChange={handleChange} fullWidth required />
        <TextField label="Email" type="email" name="email" value={formData.email} onChange={handleChange} fullWidth required />
        <TextField
          label="Phone Number"
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          fullWidth
          required
        />

        <TextField label="Password" type="password" name="password" value={formData.password} onChange={handleChange} fullWidth required />

        <FormControl fullWidth>
          <InputLabel>Grade</InputLabel>
          <Select value={formData.grade} onChange={(e) => handleSelectChange("grade", e.target.value)}>
            <MenuItem value="9">Grade 1</MenuItem>
            <MenuItem value="10">Grade 2</MenuItem>
            <MenuItem value="11">Grade 3</MenuItem>
            <MenuItem value="12">Grade 4</MenuItem>
            <MenuItem value="12">Grade 5</MenuItem>
            <MenuItem value="12">Grade 6</MenuItem>
          </Select>
        </FormControl>

        <TextField label="Age" type="number" name="age" value={formData.age} onChange={handleChange} fullWidth required />
        <TextField label="School" name="school" value={formData.school} onChange={handleChange} fullWidth required />

        <FormControl fullWidth>
          <InputLabel>Coach Selection</InputLabel>
          <Select value={formData.coachSelection} onChange={(e) => handleSelectChange("coachSelection", e.target.value)}>
            <MenuItem value="auto">Assign a coach automatically</MenuItem>
            <MenuItem value="manual">Enter coach ID manually</MenuItem>
          </Select>
        </FormControl>

        {formData.coachSelection === "manual" && (
          <TextField label="Coach ID" name="coachId" value={formData.coachId} onChange={handleChange} fullWidth required />
        )}

        <Button type="submit" variant="contained" color="primary" fullWidth>Sign Up</Button>
      </form>
    </div>
  );
}

export default SignUpForm;
