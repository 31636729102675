import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import "./i18n/config";
import PurchaseButton from "./components/Payment/PurchaseButton";
import GoogleAuth from "./apps/account/components/GoogleAuth";
import LandingPage from "./components/LandingPage";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    {/* <GoogleAuth /> */}
    <App />
    {/* <PurchaseButton /> */}
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
