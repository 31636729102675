import React, { useState } from "react";
import { auth, db } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, getDocs, collection } from "firebase/firestore";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FlashlightOff } from "lucide-react";

const Register = () => {
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        password: "",
        confirmPassword: "",
    });

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const generateCoachId = async () => {
        const coachesCollection = await getDocs(collection(db, "coaches"));
        const nextId = coachesCollection.docs.length + 1;
        return `C00-${nextId}`;
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        if (formData.password !== formData.confirmPassword) {
            setError("Passwords do not match.");
            return;
        }
        setLoading(true);
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
            const user = userCredential.user;

            //Generate coach id
            const coachId = await generateCoachId();

            // Store user data in Firestore
            await setDoc(doc(db, "coaches", user.uid), {
                uid: user.uid,
                coachId: coachId,
                name: formData.fullName,
                email: formData.email,
                status: "Pending Approval", // Coach needs approval
                profileCompleted: false,
            });

            alert("Registration Successful! Awaiting approval.");
            navigate("/coach/signin");
        } catch (err) {
            setError(err.message);
        }
        setLoading(false);
    };

    return (
        <div className="min-h-screen flex items-center justify-center md:mt-10">
            <div className="area w-full top-0 left-0 h-full">
                <ul className="circles">
                    <li className="bg-[#84d4d8]"></li>
                    <li className="bg-[#a43bcb]"></li>
                    <li className="bg-[#e293ac]"></li>
                    <li className="bg-[#fb9160]"></li>
                    <li className="bg-[#84d4d8]"></li>
                    <li className="bg-[#fcce90]"></li>
                    <li className="bg-[#a43bcb]"></li>
                    <li className="bg-[#fb9160]"></li>
                    <li className="bg-[#e293ac]"></li>
                    <li className="bg-[#84d4d8]"></li>
                    <li className="bg-[#fcce90]"></li>
                    <li className="bg-[#e293ac]"></li>
                    <li className="bg-[#a43bcb]"></li>
                    <li className="bg-[#fb9160]"></li>
                </ul>
            </div>
            <div className="bg-white/90 shadow-lg rounded-lg p-8 max-w-md w-full">
                <h2 className="comic-sans-medium text-2xl font-bold text-gray-800 mb-6 text-center">Coach Registration</h2>
                <form onSubmit={handleRegister}>
                    <div className="mb-4">
                        <label htmlFor="name" className="comic-sans-small block text-gray-800 font-medium mb-1">
                            Full Name
                        </label>
                        <input
                            type="text"
                            id="fullName"
                            name="fullName"
                            className="w-full bg-gray-100 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-[#F0CF2D]"
                            placeholder="Enter your full name"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="email" className="comic-sans-small block text-gray-800 font-medium mb-1">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="w-full bg-gray-100 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-[#F0CF2D]"
                            placeholder="Enter your email"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="password" className="comic-sans-small block text-gray-800 font-medium mb-1">
                            Password
                        </label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            className="w-full bg-gray-100 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-[#F0CF2D]"
                            placeholder="Create a password"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="password" className="comic-sans-small block text-gray-800 font-medium mb-1">
                            Confirm Password
                        </label>
                        <input
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            className="w-full bg-gray-100 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-[#F0CF2D]"
                            placeholder="Confirm Password"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button type="submit" className="w-full bg-[#F0CF2D] text-gray-800 py-2 rounded-md hover:bg-[#E1BC28] font-semibold flex items-center justify-center" disabled={loading}>
                        {loading ? (
                            <svg className="animate-spin h-5 w-5 mr-2 border-t-2 border-white rounded-full" viewBox="0 0 24 24"></svg>
                        ) : (
                            "Register"
                        )}
                    </button>
                </form>
                {error && <p className="text-lg text-red-500">{error}</p>}
                <p className="comic-sans-tiny text-gray-600 text-sm text-center mt-4">
                    Already registered?{" "}
                    <Link to="/coach/signin" className="text-gray-800 hover:text-[#F0CF2D] hover:underline font-medium">
                        Log in here
                    </Link>
                </p>
            </div>
        </div>
    );
};

export default Register;
