// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc, getDocs, query, where } from "firebase/firestore";
import { createUserWithEmailAndPassword, verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCfGb33kjs7gEaljmZnHJI4F4NEnCfOFdI",
    authDomain: "kidz-e-learning.firebaseapp.com",
    projectId: "kidz-e-learning",
    storageBucket: "kidz-e-learning.appspot.com",
    messagingSenderId: "762192583663",
    appId: "1:762192583663:web:2a45f27d872f9bdbe4741a",
    measurementId: "G-1KW1SFJK50"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
const provider = new GoogleAuthProvider();
provider.setCustomParameters({
    prompt: "select account "
});
export const auth = getAuth(app);
export const signInWithGooglePopup = async () => {
    try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;

        // Save user details to Firestore
        const userDoc = {
            uid: user.uid,
            email: user.email,
            password: user.password,
            firstname: user.firstname,
            lastname: user.lastname,
            phoneNumber: user.phoneNumber || null,
            createdAt: new Date(),
        };

        // Check if the user already exists in Firestore
        const usersCollection = collection(db, "users");
        const userSnapshot = await getDocs(query(usersCollection, where("uid", "==", user.uid)));

        if (userSnapshot.empty) {
            await addDoc(usersCollection, userDoc);
        }

        return user;
    } catch (error) {
        console.error("Error during Google Sign-In:", error.message);
        throw error;
    }
}


// document.addEventListener('DOMContentLoaded', () => {
//     // TODO: Implement getParameterByName()

//     // Get the action to complete.
//     const mode = getParameterByName('mode');
//     // Get the one-time code from the query parameter.
//     const actionCode = getParameterByName('oobCode');
//     // (Optional) Get the continue URL from the query parameter if available.
//     const continueUrl = getParameterByName('continueUrl');
//     // (Optional) Get the language code if available.
//     const lang = getParameterByName('lang') || 'en';

//     // Configure the Firebase SDK.
//     // This is the minimum configuration required for the API to be used.
//     const config = {
//         apiKey: "AIzaSyCfGb33kjs7gEaljmZnHJI4F4NEnCfOFdI",
//         authDomain: "kidz-e-learning.firebaseapp.com",
//         projectId: "kidz-e-learning",
//         storageBucket: "kidz-e-learning.appspot.com",
//         messagingSenderId: "762192583663",
//         appId: "1:762192583663:web:2a45f27d872f9bdbe4741a",
//         measurementId: "G-1KW1SFJK50" // Copy this key from the web initialization
//         // snippet found in the Firebase console.
//     };
//     const app = initializeApp(config);
//     const auth = getAuth(app);

//     // Handle the user management action.
//     switch (mode) {
//         case 'resetPassword':
//             // Display reset password handler and UI.
//             handleResetPassword(auth, actionCode, continueUrl, lang);
//             break;
//         case 'recoverEmail':
//             // Display email recovery handler and UI.
//             handleRecoverEmail(auth, actionCode, lang);
//             break;
//         case 'verifyEmail':
//             // Display email verification handler and UI.
//             handleVerifyEmail(auth, actionCode, continueUrl, lang);
//             break;
//         default:
//         // Error: invalid mode.
//     }
// }, false);

// function logIn(email, password) {
//     return signInWithEmailAndPassword(auth, email, password);
// }
function signUp(firstname, lastname, email, phoneNumber, password) {
    return createUserWithEmailAndPassword(auth, email, password, firstname, lastname, phoneNumber);
}

// function handleResetPassword(auth, actionCode, continueUrl, lang) {
//     verifyPasswordResetCode(auth, actionCode).then((email) => {
//         const accountEmail = email;

//         // TODO: Show the reset screen with the user's email and ask the user for
//         // the new password.
//         const newPassword = "...";

//         // Save the new password.
//         confirmPasswordReset(auth, actionCode, newPassword).then((resp) => {
//             // Password reset has been confirmed and new password updated.

//             // TODO: Display a link back to the app, or sign-in the user directly
//             // if the page belongs to the same domain as the app:
//             // auth.signInWithEmailAndPassword(accountEmail, newPassword);

//             // TODO: If a continue URL is available, display a button which on
//             // click redirects the user back to the app via continueUrl with
//             // additional state determined from that URL's parameters.
//         }).catch((error) => {
//             // Error occurred during confirmation. The code might have expired or the
//             // password is too weak.
//         });
//     }).catch((error) => {
//         // Invalid or expired action code. Ask user to try to reset the password
//         // again.
//     });
// }
// function logOut() {
//     return signOut(auth);
// }
// function googleSignIn() {
//     const googleAuthProvider = new GoogleAuthProvider();
//     return signInWithPopup(auth, googleAuthProvider);
// }

// useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
//         console.log("Auth", currentuser);
//         setUser(currentuser);
//     });

//     return () => {
//         unsubscribe();
//     };
// }, []);